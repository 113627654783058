import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TabBar from "../../components/TabBar";
import { servicesFields } from "../../metadata";
import Grid from "@material-ui/core/Grid";
import { withPrefix, Link } from "gatsby";
import Layout from '../../components/layout';
import classnames from "classnames";

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  article: {
    ...theme.typography.article,
    textTransform: "uppercase",
    paddingTop: theme.spacing.unit * 3
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  },
  ul: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    fontSize: "22px"
  },
  anchor: {
    color: "rgba(0, 156, 219, 255)"
  },
  container: { marginTop: "44px", marginBottom: "44px" },
  box: {
    border: "1px solid #959595",
    padding: "30px 20px",
    marginTop: theme.spacing.unit * 3
  },
  boxHeading: {
    ...theme.typography.subheading,
    fontSize: "30px",
    color: "rgba(37, 54, 156, 255)",
    fontWeight: "400",
    textTransform: "uppercase"
  },
  boxText: { paddingLeft: "10px" }
});

const salesforces = [
  'Sales Cloud', 'Service Cloud', 'Community Cloud', 'Pardot',
  'CPQ Field Services (FSL)', 'Commerce Cloud (Demandware)',
  'Lightening Experiences', 'Einstein Analytics, Bots, Services'
];

const Salesforce = ({ classes, location }) => (
  <Layout location={location}>
    <React.Fragment>
      <TabBar fields={servicesFields} />
      <div className={classes.root}>
        <div className={classnames(classes.subheading, classes.container)}>
          WE WILL PUT SALESFORCE TO WORK FOR YOU
        </div>
        <Grid container justify="space-between">
          <Grid item sm={12} md={6}>
            <div className={classes.paragraph}>
              The Salesforce cloud-based CRM, Marketing Automation, Application
              Development, Commerce products lead the pack in terms of helping
              businesses quickly roll out fast-integrating, user-friendly
              applications that scale.
            </div>
            <div className={classes.paragraph}>
              Enterprises rarely use Salesforce as standalone solution. But
              that’s fine because we ensure that Salesforce plays nice with other
              systems. Primoko has the expertise you need to customize and
              connect Salesforce to legacy systems, SaaS applications, and other
              business software. This applies whether you’re just starting with
              Salesforce, re-engineering the way you do business, or in need of
              ongoing maintenance to continually fine-tune the system for
              increased functionality, usability, and adoption.
            </div>
            <div className={classes.paragraph}>
              <Link to="/letstalk"> Contact us</Link> today.
              Let’s discuss how to put Salesforce to work for you.
            </div>
          </Grid>
          <Grid item sm={12} md={5}>
            <div className={classes.box}>
              <div className={classes.boxHeading}>We speak Salesforce</div>
              <div className={classes.paragraph}>
                We've worked with:
              </div>
              <Grid container alignItems="center" justify="space-between">
                {salesforces.map((item, index) =>
                  <Grid item xs={12} className={classes.paragraph} key={index}>
                    <img
                      src={withPrefix("icons/check.png")}
                      width="16"
                      height="17"
                    />
                    <span className={classes.boxText}>{item}</span>
                  </Grid>
                )}
              </Grid>
              <div className={classes.paragraph}>
                Questions?
                <Link to="/letstalk"> Contact us</Link>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  </Layout>
);

export default withStyles(styles)(Salesforce);
