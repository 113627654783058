import React from "react";
import { withStyles } from "@material-ui/core/styles";

import Salesforce from "../../containers/services/Salesforce";
import { graphql } from 'gatsby';

const styles = theme => ({
  container: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing.unit * 4,
      paddingRight: theme.spacing.unit * 4,
      paddingTop: theme.spacing.unit * 2,
      paddingBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: theme.spacing.unit * 22,
      paddingRight: theme.spacing.unit * 22,
      paddingTop: theme.spacing.unit * 5,
      paddingBottom: theme.spacing.unit * 5
    }
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.mtitle
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.title
    }
  },
  paragraph: {
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.paragraph
    },
    [theme.breakpoints.up("sm")]: {
      ...theme.typography.paragraph
    }
  },
  subheading: {
    ...theme.typography.subheading
  },
  space: {
    marginTop: theme.spacing.unit * 4
  },
  block: {
    [theme.breakpoints.up("sm")]: {
      width: "258px"
    },
    height: "238px",
    boxShadow: "0 2px 7px 0 rgba(0, 0, 0, 0.05)",
    border: "solid 1px #6f73ff",
    padding: "0 6%"
  },
  divider: {
    borderBottom: "solid 2px #6f73ff",
    paddingBottom: "4%"
  },
  icon: {
    width: "14px",
    fill: "#6f73ff"
  },
  subpara: {
    fontFamily: "Lato",
    fontSize: "12px",
    fontWeight: "normal",
    fontStyle: "normal",
    fontStretch: "normal",
    lineHeight: "1.67",
    letterSpacing: "normal",
    color: "#666666"
  },
  textGroup: {
    display: "flex",
    paddingBottom: "5%"
  },
  left: {
    textAlign: "left"
  }
});

const ServicesSalesForcePagePreviewTemplate = ({ data, classes }) => {
  const { frontmatter } = data.markdownRemark;

  return <Salesforce />;
};

export default withStyles(styles)(ServicesSalesForcePagePreviewTemplate);

export const pageQuery = graphql`
  query ServicesSalesForcePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        description
        testimonials {
          author
          authorRole
          quote
        }
      }
    }
  }
`;
